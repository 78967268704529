:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._fd-column{flex-direction:column;}
:root ._jc-flex-end{justify-content:flex-end;}
:root ._pb-t-space-1{padding-bottom:var(--t-space-1);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-600{font-weight:600;}
:root ._ls-f-letterSpa1360334201{letter-spacing:var(--f-letterSpacing-5);}
:root ._fos-f-size-5{font-size:var(--f-size-5);}
:root ._lh-f-lineHeigh112924{line-height:var(--f-lineHeight-5);}
:root ._col-textLink{color:var(--textLink);}
:root ._pr-t-space-2{padding-right:var(--t-space-2);}
:root ._pl-t-space-2{padding-left:var(--t-space-2);}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
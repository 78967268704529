:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-2{font-weight:var(--f-weight-2);}
:root ._ls-f-letterSpa1360334204{letter-spacing:var(--f-letterSpacing-2);}
:root ._fos-f-size-2{font-size:var(--f-size-2);}
:root ._lh-f-lineHeigh112920{line-height:var(--f-lineHeight-1);}
:root ._col-color{color:var(--color);}
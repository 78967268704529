:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pr-t-space-bas101{padding-right:var(--t-space-base);}
:root ._pl-t-space-bas101{padding-left:var(--t-space-base);}
:root ._w-760px{width:760px;}
:root ._maw-10037{max-width:100%;}
:root ._mb-t-space-bas101{margin-bottom:var(--t-space-base);}
:root ._mt-t-space-bas101{margin-top:var(--t-space-base);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-5{font-weight:var(--f-weight-5);}
:root ._ls-f-letterSpa1360334201{letter-spacing:var(--f-letterSpacing-5);}
:root ._fos-f-size-5{font-size:var(--f-size-5);}
:root ._lh-f-lineHeigh112928{line-height:var(--f-lineHeight-9);}
:root ._col-textSeconda3655{color:var(--textSecondary);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-normal{white-space:normal;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._fow-700{font-weight:700;}
:root ._col-text{color:var(--text);}
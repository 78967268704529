:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._bg-backgroundE1259168129{background-color:var(--backgroundEmphasis);}
:root ._ac-center{align-content:center;}
:root ._ai-center{align-items:center;}
:root ._fd-row{flex-direction:row;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._maw-10037{max-width:100%;}
:root ._pr-t-space-bas101{padding-right:var(--t-space-base);}
:root ._pl-t-space-bas101{padding-left:var(--t-space-base);}
:root ._mr-0px{margin-right:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._pt-24px{padding-top:24px;}
:root ._pb-24px{padding-bottom:24px;}
:root ._w-10037{width:100%;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._mt-0px{margin-top:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._w-1300px{width:1300px;}
:root ._pt-0px{padding-top:0px;}
:root ._pb-0px{padding-bottom:0px;}
:root ._pt-t-space-bas101{padding-top:var(--t-space-base);}
:root ._pb-t-space-bas101{padding-bottom:var(--t-space-base);}